<template>
  <v-alert
    dense
    icon="mdi-bank-transfer"
    prominent
  >
    <v-card-title>
      <span v-t="'invoices.easySoftwareBankContact'" />
    </v-card-title>

    <v-card-text>
      <v-row class="details-table">
        <v-col>
          <div class="mb-4">
            <div class="overline">
              <span v-t="'invoices.accountName'" />
            </div>
            <div>{{ data.name }}</div>
          </div>
          <div class="mb-4">
            <div class="overline">
              <span v-t="'invoices.iban'" />
            </div>
            <td>{{ data.iban }}</td>
          </div>
          <div class="mb-4">
            <div class="overline">
              <span v-t="'invoices.swiftBic'" />
            </div>
            <div>{{ data.swift }}</div>
          </div>
        </v-col>
        <v-col>
          <div class="mb-4">
            <div class="overline">
              <span v-t="'invoices.currency'" />
            </div>
            <div>{{ data.currency }}</div>
          </div>
          <v-alert
            color="info"
            dense
            outlined
          >
            <v-icon
              color="info"
              class="mr-2"
            >
              {{ infoIcon }}
            </v-icon>
            <small
              v-t="
                'invoices.pleaseNoteThatVariableSymbolIsAvailableInEachInvoice'
              "
            />
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-alert>
</template>

<script>
import { mapGetters } from 'vuex'
import { INFO_ICON } from '../../../constants/app'

export default {
  data: () => ({
    infoIcon: INFO_ICON
  }),
  computed: {
    ...mapGetters('invoicesAndQuotations', ['bankAccount']),
    data () {
      return this.bankAccount(this.$route.params.clientId)
    }
  }
}
</script>
